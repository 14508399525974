<template>
    <div class="card">
        <h1 class="tw-text-xl tw-font-bold tw-mb-8">Notifications</h1>

        <DataTable
          :value="data"
          :paginator="true"
          :rows="10"
          dataKey="id"
          :rowHover="true"
          :rowClass="rowClass"
          @row-click="rowClick"
          responsiveLayout="scroll"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="perPageOptions"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        >
            <template #empty>
                <div class="tw-flex tw-justify-center tw-items-center tw-py-20 tw-text-2xl tw-font-bold">
                Tidak ada data yang ditemukan
                </div>
            </template>
            <template #loading>
                <div class="tw-flex tw-justify-center tw-items-center tw-py-20">
                Loading records, please wait...
                </div>
            </template>
            <Column header="Title" field="title" style="min-width: 2rem" />
            <Column header="Message" field="message" style="min-width: 8rem" />
        </DataTable>
    </div>
</template>

<script>
export default {
    name: 'notification',
    data() {
        return {
            perPageOptions: [10, 20, 50],
            data: [],
        }
    },
    mounted () {
        this.getData();
    },
    methods: {
        getData() {
            this.$http
                .get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/notification/list?type&is_read&limit=&offset=0`)
                .then(response => {
                    this.data = response.data.data.notifications
                })
        },
        rowClass(item) {
            return !item.is_read ? 'active' : ''
        },
        rowClick(event) {
            let index = event.index
            let item = event.data
            console.log(event, index, item);
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep(tr.active) {
    background-color: azure;
}
</style>